.reservationDetailContainer {
  padding: 32px;
  line-height: 25px;
}

.headerDetailText {
  font-size: 14px;
  color: #6b6b6b;
}

.detailDateText {
  font-size: 20px;
}

.reservationDetailInnerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.detailGuestNameText {
  font-size: 32px;
  line-height: normal;
}

.reservationDetailCheckInContainer {
  width: 25%;
}

.reservationDetailGuestContainer {
  width: 50%;
}

.reservationDetailBookerContainer {
  width: 25%;
}

@media print {
  .detailGuestNameText {
    font-size: 20px;
  }

  .detailDateText {
    font-size: 16px;
  }
}
