.hotelPropertyDetailsContainer {
  display: none;
}
.mobilePaginationBarContainer {
  display: none;
}

@media screen and (max-width: 50rem) {
  .hotelPropertyDetailsContainer {
    margin-left: 16px;
    margin-right: 16px;
    padding: 8px;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    line-height: 25px;
    gap: 10px;
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
  }
  .hotelPropertyDetailContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .hotelNameText {
    font-size: 16px;
  }
  .hotelIdText {
    font-size: 12px;
  }
  .hotelAddressText {
    font-size: 14px;
  }
  .mobilePaginationContainer {
    display: block;
    background: red;
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
    margin-left: 16px;
    margin-right: 16px;
  }
  .mobilePaginationBarContainer {
    display: flex;
    justify-content: center;
    background: white;
    padding: 8px;
  }
}
