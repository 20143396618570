.reservationListContainer {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 0 auto;
  gap: 8px;
  padding-top: 30px;
}

.NoReservationDisplay {
  font-size: 14px;
  text-align: center;
  padding: 32px;
}

.partnerFooterContainer {
  display: flex;
  justify-content: center;
  gap: 36px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #f2f2f2;
  height: auto;
}

.partnerFooterContainer p {
  color: #0071c2;
}

@media screen and (max-width: 600px) {
  .reservationListContainer {
    width: 100%;
    padding-top: 15px;
    background-color: white;
  }
  .partnerViewPageHeaderText {
    padding-left: 20px;
    padding-right: 20px;
  }
  .partnerFooterContainer {
    display: none;
  }
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
  .reservationListContainer {
    width: 100%;
    padding-top: 15px;
    background-color: white;
  }
}
