.partnerInActivitySection {
  background-color: #f2f2f2;
  padding: 32px 0 32px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.partnerInActivityContainer {
  width: 40%;
  border: 1px solid #d9d9d9;
}

.partnerInActivityContent {
  padding: 64px;
  font-size: 20px;
  line-height: 30px;
}

.partnerLogOutContent {
  padding: 64px;
  font-size: 20px;
}

.secureLoginLinkButton {
  border: none;
  background: none;
  color: #0071c2;
}

.partnerInActivityFooterContainer {
  display: flex;
  justify-content: center;
  gap: 36px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #f2f2f2;
  height: auto;
}

.partnerInActivityFooterContainer p {
  color: #0071c2;
}

@media screen and (max-width: 50rem) {
  .partnerInActivitySection {
    padding: 0;
  }
  .partnerInActivityContainer {
    width: inherit;
  }
  .partnerInActivityContent {
    padding: 24px;
  }
}
