@media screen and (max-width: 50rem) {
  .MobileHotelReservationTableContainer {
    margin-top: 20px;
  }
  .mobileHotelReservationTableCardContainer {
    margin-left: 20px;
    margin-right: 20px;
    border-bottom: 1px solid #d9d9d9;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    line-height: 23px;
  }
  .mobileSingleReservationContainer {
    display: flex;
    gap: 5px;
  }
  .mobileSingleReservationDateText {
    font-size: 12px;
  }
  .mobileReservationGuestNameText {
    font-size: 16px;
  }
  .partnerReservationPaginationBarContainer {
    padding-inline-start: initial;
  }
  .mobileHotelReservationTablePagination {
    margin-top: 15px;
    padding: 12px;
    display: flex;
    justify-content: center;
  }
}
