.selectedLanguage {
  width: 100%;
}
.spui_avatar__image {
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
  background-color: #f2f2f2;
  border: 1px solid #e6e6e6;
}

.listItemSelected {
  color: #0071c2;
  background: rgba(0, 113, 194, 0.06);
}

.spui_modal__content a {
  text-decoration: none;
  color: #333;
}

.spui_modal {
  width: 100%;
  max-width: 60rem;

  top: 50%;
  left: 50%;
  padding: 2rem;
  position: fixed;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 2px;
}

.spui_modal__header {
  display: flex;
  justify-content: space-between;
  align-self: stretch;
}

.spui_modal__header__title {
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.125rem;
  color: #262626;
}

.spui_modal__content {
  display: flex;
  flex-wrap: wrap;
  padding: 0.625rem;
  gap: 1.25rem;
  list-style-type: none;
}

.spui_modal__content__listItem {
  display: flex;
  flex-direction: row;
  width: 12.5rem;
  justify-content: space-between;
  padding: 7px;
}

.lStart {
  display: flex;
  flex-direction: row;
}

.title {
  font-size: 1rem;
  padding-left: 0.625rem;
  padding-top: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
  color: #262626;
}

.flag {
  padding-top: 0.3125rem;
}

.lEnd {
  display: flex;
  align-items: flex-end;
  padding-right: 0.3125rem;
}

.spui_modal__close {
  height: 2.5rem;
  width: 2.5rem;
  opacity: 0.4;
  border: 0;
  cursor: pointer;
  background: 0;
  margin-top: 0.625rem;
  padding-right: 0.3125rem;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 100;
}
.languageItem:hover {
  background: rgba(0, 0, 0, 0.06);
}

@media screen and (max-width: 50rem) {
  .spui_modal {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .spui_modal__header {
    padding: 1rem;
  }

  .spui_modal__header__title {
    font-size: 1rem;
  }

  .spui_modal__content {
    overflow: auto;
    padding: 0;
  }

  .languageItem {
    width: 100%;
  }

  .spui_modal__content__listItem {
    width: auto;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 2rem;
  }

  .listItemSelected {
    background: #e4f4ff;
  }
}
