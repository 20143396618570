.navbarContainer {
  background: #003580;
  padding: 14px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: auto;
}

.logoContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  cursor: pointer;
}

.logo {
  height: 24px;
}

.headerText {
  color: #f2f2f2;
}

.connectContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.phoneIcon {
  height: 16px;
  width: 16px;
}

.connectViaPhoneText {
  color: #f2f2f2;
  font-size: 14px;
}

.navbarRightContainer {
  display: flex;
  gap: 12px;
  align-items: center;
}

.selectLanguageContainer {
  width: auto;
}

.headerSearchIconImg {
  display: none;
}

.partnerLogoutButton {
  cursor: pointer;
}

.leftContainer {
  display: flex;
}

.mobileDropDown {
  display: none;
}

.dropDown {
  display: block;
}

@media screen and (max-width: 50rem) {
  .headerSearchIconImg {
    display: block;
    width: 25px;
    height: 25px;
    align-self: center;
    cursor: pointer;
  }

  .mobileDropDown {
    display: flex;
    background: #1f4e90;
  }

  .dropDown {
    display: none;
  }

  .mobileDropDownMenuItem {
    white-space: pre-wrap !important;
  }
}

@media print {
  .navbarRightContainer {
    display: none;
  }
}
