.appInfoContainer {
  background: #fff0e0;
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: auto;
  width: auto;
}

.infoSign {
  width: 24px;
  height: 24px;
}

.alertInfo {
  margin-top: 10px;
  font-size: 14px;
}

@media screen and (max-width: 50rem) {
  .appInfoContainerDiv {
    display: none;
  }
}

@media print {
  .appInfoContainer {
    display: none;
  }
}
