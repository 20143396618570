.mobileReservationIdForm {
  display: none;
}

.mobileReservationSearchButton {
  width: auto;
}

@media screen and (max-width: 50rem) {
  .mobileReservationIdForm {
    background: white;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
    height: 100vh;
    padding: 24px;
  }

  .mobileReservationSearchButton {
    cursor: pointer;
    border: none;
    color: white;
    background: #0071c2;
  }
}
