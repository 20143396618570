.hotelReservationInputsContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.selectDateLabelText {
  color: black;
  font-size: 14px;
  margin-bottom: 1px;
  margin-top: 1px;
  border-radius: 2px;
}

.selectDateContainer {
  height: 40px;
  width: 125px;
  background: white;
  border-radius: unset !important;
  margin-top: 2px;
}

.searchReservationButton {
  background: #0071c2;
  border: none;
  color: white;
  cursor: pointer;
  height: 20%;
  margin-top: 22px;
}

.searchInput {
  width: 290px;
}
.datePickerFieldContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

@media screen and (max-width: 600px) {
  .hotelReservationInputsContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .selectDateOuterContainer {
    width: 100%;
  }

  .datePickerFieldContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
  }
  .dateFieldInputDiv {
    width: 100%;
  }

  .searchInput {
    width: 100%;
  }

  .searchReservationButton {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
  .hotelReservationInputsContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .selectDateOuterContainer {
    width: 100%;
  }

  .datePickerFieldContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
  }
  .dateFieldInputDiv {
    width: 100%;
  }

  .searchInput {
    width: 100%;
  }

  .searchReservationButton {
    margin-bottom: 15px;
  }
}
