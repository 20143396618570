.lSelector {
  max-width: var(--max-width);
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 100%;
}

.spui_modal__content__listItem {
  display: flex;
  flex-direction: row;
  gap: 0;
}

.spui_avatar__image {
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
  background-color: #f2f2f2;
  border: 1px solid #e6e6e6;
}

.selectedFlag {
  z-index: 4;
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 50rem) {
  .spui_modal__content__listItem {
    width: 100%;
    justify-content: space-between;
  }
  .lSelector .selectedFlag {
    background-color: var(--main-color) !important;
  }
}
