@media screen and (max-width: 50rem) {
  .mobileReservationDetailCardContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 16px;
  }
  .mobileHeaderDetailText {
    font-size: 14px;
    color: #6b6b6b;
  }
  .mobileDetailGuestNameText {
    font-size: 24px;
    line-height: normal;
  }
  .mobileReservationCheckinAndCheckoutContainer {
    display: flex;
    gap: 20px;
  }
  .mobileDetailDateText {
    font-size: 16px;
  }
  .mobileBookingNumberAndBookedOnContainer {
    display: flex;
    gap: 20px;
  }
  .mobileDetailValueText {
    font-size: 16px;
  }
}
