.partnerReservationDetailViewContainer {
  margin-top: 50px;
  margin-left: 150px;
  margin-right: 150px;
}

.returnToReservationListButton {
  border: none;
  font-size: 14px;
  color: #0071c2;
  background: none;
}

.roomDetailContainer {
  margin-top: 20px;
  padding: 32px;
  line-height: 28px;
}

.roomDetailHeaderText {
  font-size: 20px;
}

.roomTextCanceledStatusTextDecoration {
  font-size: 20px;
  color: #949494;
  text-decoration: line-through;
}

.reservationDetailPartnerFooter {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #0071c2;
  background-color: #f2f2f2;
}

.partnerReservationDetailViewOuterContainer {
  display: flex;
  gap: 20px;
}

.partnerReservationViewGuestDetailContainer {
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
}

.partnerReservationDetailPrintContainer {
  width: 40%;
}

.printContainer {
  padding: 32px;
}

.printButton {
  border: 1px solid #0071c2;
  color: #0071c2;
  padding: 8px 16px 8px 16px;
  background-color: white;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
}

.statusTextOk {
  color: #262626;
}

.statusTextCancelled {
  color: red;
}

@media screen and (max-width: 50rem) {
  .partnerReservationDetailViewContainer {
    margin: unset;
  }

  .returnToReservationListButton {
    display: none;
  }

  .partnerReservationDetailViewContainer h1 {
    display: none;
  }

  .partnerReservationDetailViewOuterContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .partnerReservationDetailPrintContainer {
    width: 100%;
  }

  .partnerReservationDetailPrintContainer p {
    margin: 10px;
  }

  .reservationDetailPartnerFooter {
    display: none;
  }

  .roomDetailContainer {
    padding: 16px;
  }
}

@media print {
  .partnerReservationDetailPrintContainer {
    display: none;
    width: 0;
  }

  .partnerReservationViewGuestDetailContainer {
    width: 100%;
  }

  .partnerReservationDetailViewContainer {
    margin-left: 20px;
    margin-right: 20px;
  }

  .reservationDetailPartnerFooter {
    display: none;
  }

  .returnToReservationListButton {
    display: none;
  }

  .appInfo {
    display: none;
  }
}
