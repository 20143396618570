.formInput {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
}

.formInput input {
  padding: 15px;
  margin: 5px 0;
  border-radius: 3px;
  border: 1px solid #949494;
  box-sizing: border-box;
  height: 40px;
  width: 100%;
}
.formInput label {
  font-size: 14px;
}
.invalidInput {
  color: #cc0000;
}
.vectorImg {
  width: 20px;
  height: 20px;
  margin-left: -2rem;
  margin-top: 1rem;
  position: absolute;
}
