.formInputContainer {
  width: 350px;
}
.reservationIdForm {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  width: 100%;
}
.searchButton {
  background: #0071c2;
  border: none;
  color: white;
  height: fit-content;
  padding: 10px 12px !important;
}

.searchIconImg {
  width: 20px;
  height: 20px;
  position: relative;
  left: 36px;
  top: 1px;
  z-index: 2;
}

.reservationIdForm input[type='text']::placeholder {
  position: relative;
  left: 10px;
}

.labelDivContainer {
  display: none;
}

.inputClassName {
  padding: 0 30px !important;
}
.invalidInputDivClassName {
  display: none;
}
.mobileReservationIdForm {
  display: none;
}

@media screen and (max-width: 50rem) {
  .reservationIdForm {
    display: none;
  }
  .mobileReservationIdForm {
    display: flex;
    flex-direction: column;
  }
}
