.partnerLandingContainer {
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0 32px 0;
  gap: 32px;
  height: auto;
}

.partnerFooterContainer {
  display: flex;
  justify-content: center;
  gap: 36px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #f2f2f2;
  height: auto;
}

.partnerFooterContainer p {
  color: #0071c2;
}

@media screen and (max-width: 50rem) {
  .footerContainerDiv {
    display: none;
  }
}
