.partnerReservationPaginationBarContainer {
  padding-inline-start: initial;
}

.partnerReservationListPaginationContainer {
  border: 1px solid #d9d9d9;
  border-top-width: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.partnerTotalNumberResultsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.guestNameText {
  color: #0071c2;
}

.bookingOkStatusText {
  font-weight: 700;
}

.bookingCancelStatusText {
  font-weight: 700;
  color: #cc0000;
}

.simpleBuiPaginationComponentContainer {
  width: 100%;
}

@media screen and (max-width: 50rem) {
  .hotelReservationTableCardContainer {
    display: none;
  }
}
