.homeGridContainer {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 80% 20%;
  height: 100%;
  background-color: #ffffff;
}

.reservationContainer {
  font-size: 14px;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
}

header {
  display: contents;
}
footer {
  display: contents;
}
main {
  display: contents;
}

.headerContainer {
  grid-column: span 2;
  background-color: #003580;
}

.mainContainer {
  display: grid;
  grid-template-columns: 50% 50%;
}

.mainSplashContainer {
  padding-left: 60px;
  padding-right: 60px;
  display: flex;
  background-color: #003580;
  justify-content: center;
}

.mainReservationContainer {
  background: white;
  grid-row: span 2;
  margin-left: auto;
  margin-right: auto;
}

.footerContainer {
  background-color: #003580;
}

@media screen and (max-width: 50rem) {
  .homeGridContainer {
    display: flex;
    flex-direction: column;
  }
  .removeSplashContent {
    display: none;
  }
  .mainSplashContainer {
    padding: 0;
    width: 100%;
  }
  .mainReservationContainer {
    width: 100%;
    padding-left: unset;
  }
  .mainReservationContainerBorder {
    border-radius: 16px 16px 0 0;
  }
  .footerContainer {
    padding-top: 30px;
    padding-bottom: unset;
  }
}
