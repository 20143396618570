.cookieBanner {
  background: #ffffff;
  box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: auto;
  position: fixed;
  bottom: 0;
  width: 100%;
  font-style: normal;
  z-index: 1;
}

.headerCloseBtn {
  height: 2.5rem;
  width: 2.5rem;
  opacity: 0.4;
  border: 0;
  cursor: pointer;
  background: 0;
  margin-top: 0.625rem;
  padding-right: 0.3125rem;
}

.cookie {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2rem 18.75rem;
  gap: 2rem;
  min-width: 48rem;
  width: 70%;
}

.managePreference {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 90%;
  padding-right: 2.5rem;
}

.manageCookiePreferences {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #262626;
  margin-block-start: unset;
  margin-block-end: unset;
}

.mangeCookieContent {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #262626;
  margin-block-start: unset;
  margin-block-end: unset;
  margin-top: 1rem;
}

.manageSettings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 10%;
  align-items: center;
}

.acceptSettings {
  color: white;
  border: none;
  background: #0071c2;
  border-radius: 2px;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  cursor: pointer;
  font-weight: 500;
}

.manageCookieSettings {
  font-size: 0.875rem;
  text-align: center;
  color: #0071c2;
  cursor: pointer;
  width: max-content;
  font-weight: 500;
  border: none;
  background: none;
  padding-top: 0.5rem;
}

@media screen and (max-width: 50rem) {
  .cookie {
    height: max-content;
    display: flex;
    flex-direction: column;
    border-radius: 0 0 0.5rem 0.5rem;
    bottom: 0;
    position: sticky;
    padding: 2rem;
    min-width: 0;
    width: 100%;
  }

  .manageSettings {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .managePreference {
    padding-right: 0;
    width: 100%;
  }

  .acceptSettings {
    width: 50%;
  }

  .manageCookieSettings {
    width: 50%;
  }
}
