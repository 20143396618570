.reservationContainer p {
  margin-block-end: unset;
  margin-block-start: unset;
  margin-inline-start: unset;
  margin-inline-end: unset;
}
.reservationContainer {
  max-width: 25rem;
}

.reservationInputContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 20px;
}

.reservationInputHeadingText {
  padding-bottom: 5px;
}

.reservationInputHeading {
  font-size: 20px;
  padding-bottom: 5px;
}

.reservationHeading {
  font-style: normal;
  font-weight: 400;
}

.reservationInputForm {
  width: 25rem;
  display: flex;
  flex-direction: column;
}
.reservationInputFormButton {
  width: 100%;
}

.reservationInputForm button {
  margin-top: 1.5rem;
  height: 36px;
  background: #0071c2;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  width: 100%;
}

.reservationInputFormImportantText {
  padding-top: 1.5rem;
}
.reservationInputPartnerLink {
  text-align: center;
  color: #0071c2;
  cursor: pointer;
}

.bookingCustomerService {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.bookingAssistance {
  line-height: 1.25rem;
  font-weight: 400;
}

.bookingCustomerNumber {
  text-align: center;
  font-size: 2rem;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

.bookingCustomerNumber p {
  font-weight: 700;
}

.bookingLanguageSupport {
  font-size: 12px;
  text-align: center;
  color: #6b6b6b;
  line-height: 1.25rem;
}

.reservationFooter {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  color: #6b6b6b;
  padding-bottom: 2rem;
}

.reservationFooterHeading {
  padding-bottom: 0.3rem;
  font-size: 20px;
}

.reservationFooter span {
  line-height: 1.25rem;
}
.bookingSistersBrand {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 1.5rem;
}
.agodaBrandContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}
.pricelineBrandContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}
.agodaLogo {
  padding-top: 0.625rem;
}
.pricelineLogo {
  margin-top: 2.4375rem;
  position: relative;
  top: -1.4375rem;
}

.formInput {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
}

.formInput input {
  padding: 15px;
  margin: 5px 0;
  border-radius: 3px;
  border: 1px solid #949494;
  box-sizing: border-box;
  height: 40px;
}
.formInput label {
  font-size: 14px;
}
.invalidInput {
  color: #cc0000;
}
.backButton {
  color: #0071c2;
  cursor: pointer;
  border: none;
  background: none;
  padding-top: 24px;
}

@media screen and (max-width: 50rem) {
  .reservationContainer {
    width: 100%;
  }
  .reservationInputContainer {
    padding: 1.5rem 2rem 2rem 1.5rem;
    margin-right: 0;
    margin-left: 0;
  }

  .reservationInputForm {
    width: 100%;
  }
  .bookingDottedLine {
    border-bottom: 8px solid #d9d9d9;
  }

  .bookingCustomerService {
    padding: 2rem 1rem 1.5rem 1rem;
    text-align: justify;
    margin: 0;
  }

  .reservationFooter {
    margin: 0;
    padding: 2rem 1rem 2rem 1rem;
  }
}
