.termsAndConditionsContainer {
  background: #ffffff;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.32);
  border-radius: 4px;
  max-width: 50rem;
  height: max-content;
}

.termsContainer {
  display: flex;
  flex-direction: column;
  padding: 3.75rem;
}

.backButton {
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #0071c2;
  cursor: pointer;
  background: none;
  border: none;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.termsAndConditionsHeading {
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.25rem;
  color: #262626;
  margin-top: 2.5rem;
}

.termsAndConditionsContent {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #262626;
}

.navLeftArrow {
  width: 1rem;
  height: 1rem;
}

@media screen and (max-width: 50rem) {
  .termsContainer {
    padding: 2rem;
  }

  .termsAndConditionsHeading {
    font-size: 1.25rem;
    line-height: 2rem;
    margin-top: 1.25rem;
  }

  .termsAndConditionsContent {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
}
