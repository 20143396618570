.contentBox {
  align-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  max-width: 50rem;
}
.splashOuterContainerStyle {
  background: #febb02;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  transform: rotate(-4deg);
  z-index: 1;
}
.splashInnerContainerStyle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #ffffff;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.32);
  border-radius: 4px;
  z-index: 2;
  transform: rotate(+4deg);
  padding: 3.75rem;
}

.splashContent {
  display: flex;
  flex-direction: column;
}
.splashTravellerReservationHeader {
  padding-bottom: 1.5rem;
}

.splashContentItem {
  padding-bottom: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 2rem;
  color: #262626;
  padding-top: 1.5rem;
}

.splashHeading {
  color: #262626;
  margin-block-start: unset;
  margin-block-end: unset;
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
}

@media screen and (max-width: 50rem) {
  .contentBox {
    align-content: center;
    flex-direction: column;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    max-width: 50rem;
  }
  .splashOuterContainerStyle {
    transform: rotate(0deg);
    z-index: 1;
    background: #ffffff;
    box-shadow: none;
    border-radius: 16px 16px 0 0;
  }
  .splashInnerContainerStyle {
    transform: rotate(0deg);
    padding: 1rem;
    box-shadow: none;
    border-radius: 16px 16px 0 0;
  }

  .splashContent {
    display: flex;
    flex-direction: column;
  }
  .splashContentItem {
    padding-bottom: 30px;
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.5rem;
    color: #262626;
    padding-top: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .splashHeading {
    color: #262626;
    margin-block-start: unset;
    margin-block-end: unset;
    font-style: normal;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 2rem;
    padding-top: 1rem;
    padding-left: 1rem;
  }

  .divider {
    border-bottom: 0.5rem solid #d9d9d9;
    width: 100%;
  }
}
