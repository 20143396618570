.bookingLogo {
  width: 15.625rem;
}

.language-select {
  color: antiquewhite;
  background-color: antiquewhite;
  border: none;
}

@media screen and (max-width: 50rem) {
  .bookingLogo {
    width: 9.375rem;
  }
}
