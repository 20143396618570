.inputContainer {
  display: flex;
  flex-direction: column;
  position: relative;
}

.inputContainer input {
  padding: 15px;
  margin: 5px 0;
  border: 1px solid #949494;
  border-radius: 2px;
  box-sizing: border-box;
  height: 40px;
}

.inputContainer label {
  font-size: 14px;
}
.invalidInput {
  color: #cc0000;
}
.vectorImg {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 32px;
  right: 5px;
}
