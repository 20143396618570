.partnerCustomerService {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  background-color: #f2f2f2;
  height: auto;
  align-items: center;
}

.partnerAssistanceText {
  text-align: justify;
  width: 40%;
}

.partnerCustomerNumber {
  text-align: center;
  font-size: 2rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  width: 40%;
}

.partnerCustomerNumber p {
  font-weight: 700;
}

.partnerLanguageSupport {
  font-size: 12px;
  text-align: center;
  color: #6b6b6b;
  line-height: 1.25rem;
  width: 35%;
}

@media screen and (max-width: 50rem) {
  .partnerAssistanceSection {
    display: none;
  }
  .partnerCustomerService {
    padding: 15px 24px 24px 24px;
  }
  .partnerAssistanceText {
    text-align: center;
    width: 100%;
  }
  .partnerCustomerNumber {
    width: 100%;
  }
  .partnerLanguageSupport {
    width: 100%;
  }
}
