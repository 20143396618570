.singlePropertyFormContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  gap: 12px;
  width: 100%;
}

.singlePropertyFormInputContainer {
  width: 350px;
}

.singlePropertyFormLabelDivContainer {
  display: none;
}

.singlePropertyFormSearchButton {
  background: #0071c2;
  border: none;
  color: white;
  height: fit-content;
  padding: 10px 12px !important;
}
