.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 400;
  padding-bottom: 1.5rem;
}

.footer p {
  margin-block-start: unset;
  margin-block-end: unset;
  margin-inline-end: unset;
  margin-inline-start: unset;
  margin-top: 1.5rem;
}
.termsAndPrivacyLink {
  display: flex;
  flex-direction: row;
  gap: 1.875rem;
}

.termsAndPrivacyLinkButton {
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  text-decoration-line: underline;
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1.125rem;
}

@media screen and (max-width: 50rem) {
  .footer {
    padding-left: 2rem;
    padding-right: 2rem;
    text-align: center;
  }

  .termsAndPrivacyLink {
    gap: 1rem;
  }
}
