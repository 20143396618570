.partnerSectionStyle {
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0 32px 0;
  gap: 9px;
  height: auto;
}

.propertyPartnerInfo {
  width: 60%;
  border: 1px solid #d9d9d9;
}

.propertyPartnerInfoText {
  font-size: 18px;
  color: #262626;
  padding: 64px;
}

.yourPropertyContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 60%;
}

.singlePropertyFormContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 60%;
}

.propertyListContainer {
  width: 60%;
  height: auto;
}

.propertyListPaginationContainer {
  border: 1px solid #d9d9d9;
  border-top-width: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.paginationBarContainer {
  padding-inline-start: initial;
}

.totalNumberResultsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 50rem) {
  .partnerSectionStyle {
    padding: unset;
    gap: unset;
  }
  .propertyPartnerInfo {
    width: 100%;
  }
  .propertyPartnerInfoText {
    padding: 24px;
    line-height: 24px;
    font-size: 14px;
  }
  .yourPropertyContainer {
    width: auto;
    padding: 16px 16px 0 16px;
    margin-bottom: 10px;
  }

  .propertyListContainer {
    display: none;
  }
}
