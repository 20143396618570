.settings {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 100;
}

.settingsContent {
  width: 100%;
  max-width: 923px;
  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 2px;
  font-style: normal;
  font-weight: 700;
}

.header {
  display: flex;
  padding-left: 1rem;
  gap: 1.5rem;
  border-bottom: 1px solid #d9d9d9;
  font-size: 1.25rem;
  justify-content: space-between;
  align-items: center;
}

.headerCloseBtn {
  height: 2.5rem;
  width: 2.5rem;
  opacity: 0.4;
  border: 0;
  cursor: pointer;
  background: 0;
  margin-top: 0.625rem;
  padding-right: 0.3125rem;
}

.left {
  width: 30%;
  font-size: 0.875rem;
}

.cookieSettingsOptions {
  list-style-type: none;
  padding-inline-start: 0;
  margin-block: 0;
}

.right {
  padding: 0.625rem;
  gap: 0.75rem;
  width: 70%;
}

.rightTitle {
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  justify-content: space-between;
  font-size: 0.875rem;
  font-weight: 700;
  color: #262626;
}

.rightContent {
  padding: 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: #262626;
  line-height: 1.125rem;
}

.rightContentParagraph {
  margin: 0;
}

.body {
  display: flex;
  min-height: 18.75rem;
}

.leftItem {
  padding: 1rem;
  font-size: 0.875rem;
  border-bottom: 1px solid #d9d9d9;
}

.itemSelected {
  background-color: #f2f2f2;
}
