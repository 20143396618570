.singlePropertySection {
  background-color: #f2f2f2;
  padding: 32px 0 32px 0;
  display: flex;
  justify-content: center;
  width: 100%;
}

.singlePropertyContainer {
  width: 40%;
  border: 1px solid #d9d9d9;
}

.singlePropertyContent {
  display: flex;
  flex-direction: column;
  padding: 64px;
}

.partnerListViewButton {
  border: none;
  background: #0071c2;
  color: white;
  margin: auto;
  padding: 8px 12px 8px 12px;
}

@media screen and (max-width: 50rem) {
  .singlePropertySection {
    padding: unset;
  }
  .singlePropertyContainer {
    width: unset;
  }
  .singlePropertyContent {
    width: unset;
    padding: 24px;
    font-size: 14px;
    line-height: 24px;
  }
  .partnerListViewButton {
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
  }
  .singlePropertyContainerDiv {
    display: none;
  }
}
