:root {
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
}

.termsAndConditions {
  width: 100%;
}

.selectedLanguageContainer {
  width: 50%;
  background-color: white;
  height: 100%;
}

.skipToMainContentLink {
  position: absolute;
  left: -9999px;
  z-index: 999;
  padding: 1em;
  color: white;
  opacity: 0;
}
.skipToMainContentLink:focus {
  left: 0;
  opacity: 1;
}

.headerLogo {
  background-color: #003580;
  width: 50%;
  justify-content: center;
  display: flex;
  height: 100%;
}
.navBar {
  display: flex;
  align-items: center;
  height: 200px;
  background-color: #003580;
}
.container {
  height: 100%;
}

.partnerContainer {
  background-color: #f2f2f2;
}
@media screen and (max-width: 50rem) {
  .container {
    background-color: #003580;
  }
  .partnerContainer {
    background-color: #f2f2f2;
    height: auto;
  }
  .headerLogo {
    margin-top: 30px;
    align-items: baseline;
  }
  .navBar {
    height: 60px;
  }
  .selectedLanguageContainer {
    background-color: #003580;
  }
}
